import DyteClient, { leaveRoomState } from "@dytesdk/web-core";

export abstract class DyteController {
    public abstract getParticipantCount(): number;
    public abstract getWaitlistedCount(): number;
    public abstract handleParticipantJoin(handler: () => void): void;
    public abstract handleParticipantLeave(handler: () => void): void;
    public abstract handleMeetingJoined(handler: () => void): void;
    public abstract handleMeetingLeft(handler: ({ state }: { state: leaveRoomState }) => void): void;
    public abstract removeAllListeners(): void;
    public abstract getMeeting(): DyteClient;
}
export class WebCoreController implements DyteController {
    constructor(private meeting: DyteClient) {
    }

    public getParticipantCount(): number {
        // We only want to include active participants, otherwise webinars will
        // be recorded with no peers on the stage
        return this.meeting.participants.active.size;
    }

    public getMeeting(): DyteClient {
        return this.meeting;
    }

    public getWaitlistedCount(): number {
        return this.meeting.participants.waitlisted.toArray().length;
    }

    public handleMeetingJoined(handler: () => void): void {
        this.meeting.self.on("roomJoined", handler);
    }

    public handleMeetingLeft(handler: ({ state }: { state: leaveRoomState }) => void): void {
        this.meeting.self.on("roomLeft", handler);
    }

    public handleParticipantJoin(handler: () => void): void {
        this.meeting.participants.active.on("participantJoined", handler);
    }

    public handleParticipantLeave(handler: () => void): void {
        this.meeting.participants.active.on("participantLeft", handler);
    }

    public removeAllListeners(): void {
        this.meeting.self.removeAllListeners();
    }
}